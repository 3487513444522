@import url("https://fonts.googleapis.com/css2?family=Anton&display=swap");

@font-face {
  font-family: "sinistre";
  src: url("./fonts/sinistre.otf") format("otf"),
    url("./fonts/sinistre.woff") format("woff");
}
video::-webkit-media-controls-fullscreen-button {
  display: none;
}
#root,
html,
body {
  height: 100%;
  overflow: hidden;
  z-index: 0;
}

:root {
  --mainColor: black;
  --detailColor: #00ff00;
  --newColor: rgb(255, 99, 203);
}

p {
  margin: 2px 0px 2px 0px;
  color: var(--mainColor);
  font-family: sinistre;
  font-size: 15px;
}

video {
  object-fit: cover;
}

.container {
  border: 2px solid black;
  padding: 5px;
  margin: 5px;
}
.containerframe {
  border-right: 2px solid black;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
  padding: 5px;
  margin: 5px;
}

.outer-container {
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container-ish {
  padding: 5px 5px 5px 5px;
  margin: 5px;
}

.chatContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 33%;
  max-width: 400px;
  min-width: 250px;
  position: absolute;
  right: 0;
  top: 10px;
  bottom: 0;
}
.userContainer {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  width: 33%;
  max-width: 400px;
  min-width: 250px;
  position: absolute;
  left: 0;
  top: 0;
}

/* ///messages */

.messageText {
  width: auto;
  min-width: 70px;
  max-width: 100%;
  letter-spacing: 0;
  float: left;
  word-wrap: break-word;
  white-space: pre-wrap;
  font-family: "sinistre";
  text-align: right;
  border-top: 2px solid var(--mainColor);
  color: var(--mainColor);

  mix-blend-mode: hard-light;
}

.messageText img {
  vertical-align: middle;
}

.messages {
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}
.messages::-webkit-scrollbar {
  display: none;
}

.messageContainer {
  width: auto;
  max-width: 400px;
  display: inline-flex;
  flex-direction: column;
}

.sentText {
  display: flex;
  align-items: center;
  color: var(--newColor);
  margin: 0;
}

.pl-10 {
  padding-right: 20px;
}

.pr-10 {
  padding-right: 20px;
  color: var(--detailColor);
}

.justifyStart {
  justify-content: flex-start;
}

.justifyEnd {
  justify-content: flex-end;
}

.caller-alert {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 999999999999999999999;
  width: auto;
  border: 2px solid var(--mainColor);
  padding: 5px;
  display: flex;
  flex-direction: column;
}

::-webkit-scrollbar {
  width: 10px;
  display: none;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
  display: none;
}
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
  display: none;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
  display: none;
}

#logo {
  -webkit-animation: spin 10s linear infinite;
  -moz-animation: spin 10s linear infinite;
  animation: spin 10s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}

.dropzone-basic {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
}

.dropzone-loading {
  background-color: rgba(255, 255, 255, 0.5);
  color: black;
}

.dropzone-succes {
  background-color: rgba(0, 255, 21, 1);
  color: white;
}
