@import url(https://fonts.googleapis.com/css2?family=Anton&display=swap);
.textContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  max-width: 200px;
  -webkit-justify-content: space-between;
          justify-content: space-between;
}

.users-headline {
  border-bottom: 2px solid var(--mainColor);
  border-top: 2px solid var(--mainColor);
  margin-top: 20px;
}

.activeItem {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
}

.activeItem img {
  padding-left: 10px;
}

.activeItem p {
  color: var(--newColor);
}

.users {
  max-height: 200px;
  overflow-y: scroll;
}

.form {
  display: -webkit-flex;
  display: flex;
  border: 2px solid var(--mainColor);
  display: flex;
}

.input {
  border: none;
  min-width: 150px;
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  /* border-bottom: 2px var(--mainColor) solid; */
  border-radius: 0;
  padding: 10px;
  font-size: 15px;
  -webkit-flex-grow: 5;
          flex-grow: 5;
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

.sendButton {
  color: #fff !important;
  text-transform: uppercase;
  text-decoration: none;
  background: var(--mainColor);
  font-family: "sinistre";
  padding: 5px;
  display: inline-block;
  border: none;
  width: auto;
}

@font-face {
  font-family: "sinistre";
  src: url(/static/media/sinistre.297d671f.otf) format("otf"),
    url(/static/media/sinistre.46be4286.woff) format("woff");
}
video::-webkit-media-controls-fullscreen-button {
  display: none;
}
#root,
html,
body {
  height: 100%;
  overflow: hidden;
  z-index: 0;
}

:root {
  --mainColor: black;
  --detailColor: #00ff00;
  --newColor: rgb(255, 99, 203);
}

p {
  margin: 2px 0px 2px 0px;
  color: black;
  color: var(--mainColor);
  font-family: sinistre;
  font-size: 15px;
}

video {
  object-fit: cover;
}

.container {
  border: 2px solid black;
  padding: 5px;
  margin: 5px;
}
.containerframe {
  border-right: 2px solid black;
  border-top: 2px solid black;
  border-bottom: 2px solid black;
  padding: 5px;
  margin: 5px;
}

.outer-container {
  width: 100%;
  height: 100%;
  top: 0;
  position: absolute;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}

.container-ish {
  padding: 5px 5px 5px 5px;
  margin: 5px;
}

.chatContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;
  width: 33%;
  max-width: 400px;
  min-width: 250px;
  position: absolute;
  right: 0;
  top: 10px;
  bottom: 0;
}
.userContainer {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-justify-content: space-between;
          justify-content: space-between;

  width: 33%;
  max-width: 400px;
  min-width: 250px;
  position: absolute;
  left: 0;
  top: 0;
}

/* ///messages */

.messageText {
  width: auto;
  min-width: 70px;
  max-width: 100%;
  letter-spacing: 0;
  float: left;
  word-wrap: break-word;
  white-space: pre-wrap;
  font-family: "sinistre";
  text-align: right;
  border-top: 2px solid black;
  border-top: 2px solid var(--mainColor);
  color: black;
  color: var(--mainColor);

  mix-blend-mode: hard-light;
}

.messageText img {
  vertical-align: middle;
}

.messages {
  overflow-y: scroll;
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
  margin-bottom: 30px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: flex-end;
          align-items: flex-end;
}
.messages::-webkit-scrollbar {
  display: none;
}

.messageContainer {
  width: auto;
  max-width: 400px;
  display: -webkit-inline-flex;
  display: inline-flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

.sentText {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  color: rgb(255, 99, 203);
  color: var(--newColor);
  margin: 0;
}

.pl-10 {
  padding-right: 20px;
}

.pr-10 {
  padding-right: 20px;
  color: #00ff00;
  color: var(--detailColor);
}

.justifyStart {
  -webkit-justify-content: flex-start;
          justify-content: flex-start;
}

.justifyEnd {
  -webkit-justify-content: flex-end;
          justify-content: flex-end;
}

.caller-alert {
  position: fixed;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
          transform: translate(-50%, -50%);
  z-index: 999999999999999999999;
  width: auto;
  border: 2px solid black;
  border: 2px solid var(--mainColor);
  padding: 5px;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
}

::-webkit-scrollbar {
  width: 10px;
  display: none;
}
::-webkit-scrollbar-track {
  background: #f1f1f1;
  display: none;
}
::-webkit-scrollbar-thumb {
  background: #888;
  border-radius: 10px;
  display: none;
}
::-webkit-scrollbar-thumb:hover {
  background: #555;
  display: none;
}

#logo {
  -webkit-animation: spin 10s linear infinite;
  animation: spin 10s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    -webkit-transform: rotate(0);
            transform: rotate(0);
  }
  100% {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.dropzone-basic {
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
}

.dropzone-loading {
  background-color: rgba(255, 255, 255, 0.5);
  color: black;
}

.dropzone-succes {
  background-color: rgba(0, 255, 21, 1);
  color: white;
}

